/* @import url("https://fonts.googleapis.com/css2?family=Amatic+SC&family=Quicksand&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400&family=Source+Serif+Pro:wght@300;400&display=swap');
.App {
  text-align: center;
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

.App-link {
  color: #61dafb;
}

